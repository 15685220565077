<template>
  <div>
    <div class="wz">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>角色列表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="box">
      <div style="padding-bottom: 10px">
        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="openAuth"
          >添加角色</el-button>
      </div>
      <div class="list" v-loading="loading">
        <el-table
          :data="list"
          :header-cell-style="{
            'font-weight': 'bold',
            color: '#555555',
            padding: '6px 0',
          }"
          stripe
          border
          style="width: 100%"
        >
          <el-table-column prop="id" align="center" label="会员ID" width="100">
          </el-table-column>
          <el-table-column prop="roleName" label="会员名称" >
          </el-table-column>
          <el-table-column prop="remake" label="角色描述" >
          </el-table-column>
          <el-table-column prop="createTime" label="添加时间">
          </el-table-column>
          <el-table-column prop="address" width="160" label="操作">
            <template slot-scope="scope">
              <el-button
                type="primary"
                @click="handleCreate(scope.row.id)"
                >编辑</el-button
              >
              <el-button
                type="danger"
                v-preventReClick
                @click="deleteRole(scope.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 添加角色 -->
    <el-dialog
      title="添加角色"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible1"
      width="50%"
      @close="cancel"
    >
      <el-form
        ref="formAddMember"
        :label-position="labelPosition"
        :rules="rules"
        label-width="80px"
        :model="formAddMember"
      >
        <el-form-item label="角色名称" prop="roleName">
          <el-input v-model="formAddMember.roleName"></el-input>
        </el-form-item>
        <el-form-item label="角色描述" prop="roleDescribe">
          <el-input v-model="formAddMember.roleDescribe"></el-input>
        </el-form-item>
        <el-form-item label="角色范围" prop="roleDescribe">
          <el-radio v-model="formAddMember.isOrg" :label="0">总站</el-radio>
          <el-radio v-model="formAddMember.isOrg" :label="1">分站</el-radio>
        </el-form-item>
        
        <el-form-item label="权限" prop="nodeString">
          <el-tree
            :data="data"
            show-checkbox
            highlight-current
            node-key="id"
            @check="handCheck1"
            :props="defaultProps"
            ref="tree1"
          ></el-tree>
        </el-form-item>
        <el-form-item class="flex justify-content-end">
          <el-button
            type="primary"
            v-preventReClick
            @click="hangAdd('formAddMember')"
            :loading="btnLoad"
            >添加</el-button
          >
        </el-form-item>
        <!-- <el-form-item style="height:0px;"></el-form-item> -->
      </el-form>
    </el-dialog>
    <!-- 修改角色 -->
    <el-dialog
      title="编辑角色"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="50%"
      @close="
        dialogVisible = false;
        id = null;
        formEditMember.nodeString='';
      "
    >
      <el-form
        ref="formEditMember"
        :label-position="labelPosition"
        label-width="80px"
        :rules="rules"
        :model="formEditMember"
      >
        <el-form-item label="角色名称" prop="roleName">
          <el-input v-model="formEditMember.roleName"></el-input>
        </el-form-item>
        <el-form-item label="角色描述" prop="roleDescribe">
          <el-input v-model="formEditMember.roleDescribe"></el-input>
        </el-form-item>
        <el-form-item label="角色范围" prop="roleDescribe">
          <el-radio v-model="formEditMember.isOrg" :label="0">总站</el-radio>
          <el-radio v-model="formEditMember.isOrg" :label="1">分站</el-radio>
        </el-form-item>
        <el-form-item label="权限" prop="">
          <el-tree
            :data="data"
            show-checkbox
            highlight-current
            node-key="id"
            @check="handCheck"
            :props="defaultProps"
            ref="tree"
          ></el-tree>
        </el-form-item>
        <el-form-item class="flex justify-content-end">
          <!-- <el-button
            @click="
              dialogVisible = false;
              id = null;
            "
            >取 消</el-button
          > -->
          <el-button
            type="primary"
            v-preventReClick
            @click="hangEdit('formEditMember')"
            :loading="btnLoad"
            >修改</el-button
          >
        </el-form-item>
        <!-- <el-form-item style="height:0px;"></el-form-item> -->
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import _api from "@/api/index";
import apiUrl from "@/api/public";
export default {
  data() {
    return {
      
      setprogramme: false,
      searchform: {
        jobName: "",
        status: "",
      },
      formAddMember: {
        roleName: "",
        roleDescribe: "",
        nodeString:"",
        isOrg:0
      },
      formEditMember: {
        roleName: "",
        roleDescribe: "",
        nodeString:"",
        isOrg:null
      },
      rules: {
        roleName: [
          { required: true, message: "请输入角色名称", trigger: "blur" },
        ],
        roleDescribe: [
          { required: true, message: "请输入角色描述", trigger: "blur" },
        ],
        nodeString: [
          { required: true, message: "请选择权限", trigger: "blur" },
        ],
      },
      list: [],
      data: [],
      loading: false,
      dialogVisible: false,
      dialogVisible1: false,
      labelPosition: "right", //控制表单对齐方式
      btnLoad: false,
      nodeString: "", //选中上传的权限
      nodeString1: "", //选中上传的权限
      defaultProps: {
        children: "child",
        label: "name",
      },
      nodeList: [],
      nodeList1: [],
      id: null,
    };
  },
  created() {
    this.getRoleList();
    this.getAllPermission();
  },
  methods: {
    openAuth() {
      this.dialogVisible1 = true;
    },
    hangAdd(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.nodeList1.length == 0) {
            this.$message({
              message: "最少选择一个权限",
              type: "warning",
            });
            return false;
          } else {
            var data = {
              id: this.id,
              roleName: this.formAddMember.roleName,
              remake: this.formAddMember.roleDescribe,
              isOrg: this.formAddMember.isOrg,
              permissionId: this.nodeList1,
            };
            _api
              .post(apiUrl.addRole, data)
              .then((res) => {
                if (res.success) {
                  this.$message.success("添加角色成功");
                  this.getRoleList();
                  this.getAllPermission()
                  this.cancel();
                }
              })
              .catch((err) => {
                console.log(err);
                this.$message.error(err);
              });
          }
        } else {
          this.$message.error("信息未填写完整，请检查！");
          return false;
        }
      });
    },
    cancel() {
      this.dialogVisible1 = false;
      this.formAddMember.roleName = this.formAddMember.roleDescribe = this.formAddMember.nodeString= "";
    },
    getRoleList() {
      this.loading = true;
      _api.get(apiUrl.queryRole, {}).then((res) => {
        this.loading = false;
        if (res.success) {
          this.list = res.data;
        }
      });
    },
    handleCreate(id) {
      this.id = id;
      this.dialogVisible = true;
      this.getPermissionByRoleId(id);
      //   根据id获取数据
    },
    getAllPermission() {
      _api
        .get(apiUrl.selectAllPermission, {})
        .then((res) => {
          if (res.success) {
            this.data = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPermissionByRoleId(id) {
      _api
        .get(apiUrl.getPermissionByRoleId, {
          roleId: id,
        })
        .then((res) => {
          if (res.success) {
            this.formEditMember.roleName = res.data.role.roleName;
            this.formEditMember.roleDescribe = res.data.role.remake;
            this.formEditMember.isOrg = res.data.role.isOrg;
            const result = res.data.premission;
            const nodeList = [];
            const resList = [];
            result.forEach((item) => {
              nodeList.push(item.id);
              item.child.forEach((it) => {
                nodeList.push(it.id);
                resList.push(it);
              });
            });
            this.nodeList = nodeList;
            this.$refs.tree.setCheckedNodes(resList);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handCheck(data, checkedNodes) {
      const nodeList = [];
      let tree = this.$refs.tree;
      let node = tree.getCheckedKeys().concat(tree.getHalfCheckedKeys());
      this.nodeList = node;
      this.formEditMember.nodeString = node.join(",");
    },
    handCheck1(data, checkedNodes) {
      const nodeList1 = [];
      let tree = this.$refs.tree1;
      let node = tree.getCheckedKeys().concat(tree.getHalfCheckedKeys());
      this.nodeList1 = node;
      this.formAddMember.nodeString = node.join(",");
    },
    hangEdit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.nodeList.length == 0) {
            this.$message({
              message: "最少选择一个权限",
              type: "warning",
            });
            return false;
          } else {
            var data = {
              id: this.id,
              roleName: this.formEditMember.roleName,
              remake: this.formEditMember.roleDescribe,
              isOrg: this.formEditMember.isOrg,
              permissionId: this.nodeList,
            };
            _api
              .post(apiUrl.addRole, data)
              .then((res) => {
                if (res.success) {
                  this.$message.success("修改角色成功");
                  this.dialogVisible = false;
                  this.id = null;
                  this.getRoleList();
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        } else {
          this.$message.error("信息未填写完整，请检查！");
          return false;
        }
      });
    },
    deleteRole(id) {
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          _api
            .get(apiUrl.deleteRole, {
              roleId: id,
            })
            .then((res) => {
              if (res.success) {
                this.$message.success("删除角色成功");
                this.getRoleList();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>
<style>
.el-loading-spinner .path {
  stroke: #fe8196;
}
.el-switch__label {
  color: #bdbdbd;
}
.el-switch__label.el-switch__label--right.is-active {
  color: #2ad1b8;
}
.el-switch__label.el-switch__label--left.is-active {
  color: #fe8496;
}
</style>
<style scoped>
@import "../../assets/css/booth.css";
.serarch {
  overflow: hidden;
}
</style>
